exports.components = {
  "component---src-pages-banquetas-js": () => import("./../../../src/pages/banquetas.js" /* webpackChunkName: "component---src-pages-banquetas-js" */),
  "component---src-pages-cadeiras-js": () => import("./../../../src/pages/cadeiras.js" /* webpackChunkName: "component---src-pages-cadeiras-js" */),
  "component---src-pages-colchoes-js": () => import("./../../../src/pages/colchoes.js" /* webpackChunkName: "component---src-pages-colchoes-js" */),
  "component---src-pages-estofados-js": () => import("./../../../src/pages/estofados.js" /* webpackChunkName: "component---src-pages-estofados-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poltronas-js": () => import("./../../../src/pages/poltronas.js" /* webpackChunkName: "component---src-pages-poltronas-js" */),
  "component---src-pages-salajantar-js": () => import("./../../../src/pages/salajantar.js" /* webpackChunkName: "component---src-pages-salajantar-js" */)
}

